import { FieldsSelection } from '@genql/runtime';
import {
  Client,
  CompensationChangeScenarioType,
  CompensationScenarioDataDumpType,
  CompensationScenarioDataDumpV2Type,
  EmployeeType,
} from 'services/generated';
import { SHALLOW_COMPENSATION_CHANGE_SCENARIO_FRAGMENT } from 'services/queries';
import {
  COMP_PLAN_CONFIG_FRAGMENT,
  COMP_SCENARIO_DETAIL_PHOENIX_RISING_FRAGMENT,
  COMPENSATION_SCENARIO_DETAIL_FRAGMENT,
  EMPLOYEE_FRAGMENT,
} from 'services/scenarios/fragments';
import {
  AllEmployeesApprovalData,
  ApprovalMapType,
  approvalMapValueType,
  CompensationScenarioDetailType,
  ExtendedCompScenarioDetailPhoenixRisingType,
  PendingApproversType,
  UnapprovedEmployeeType,
  unifiedEmployeeType,
} from 'services/scenarios/types';

export const EMPLOYEE_ID_FRAGMENT = {
  id: true,
};

export const SHALLOW_ROLE_SUMMARY = {
  jobFamily: true,
  lowerBoundAnnualSalaryCents: true,
  upperBoundAnnualSalaryCents: true,
  salaryCurrencyCode: true,
  level: true,
  jobTrack: true,
  levelNum: true,
  id: true,
  title: true,
};

const VESTING_SCHEDULE_FRAGMENT = {
  vestingDuration: true,
  vestingFrequency: true,
  hasCliff: true,
};

export const SHALLOW_SECURITY_SET_SUMMARY = {
  issuedQuantity: true,
  vestingStartDate: true,
  vestingSchedule: VESTING_SCHEDULE_FRAGMENT,
};
export const SHALLOW_STAKEHOLDER_SUMMARY = {
  securitySet: SHALLOW_SECURITY_SET_SUMMARY,
};

export const SHALLOW_PROFILE_SUMMARY = {
  email: true,
};

export const EMPLOYMENT_FRAGMENT = {
  id: true,
  payRateInCents: true,
  payCurrency: true,
  effectiveDate: true,
  annualizedPayRate: true,
};

export const SHALLOW_WORK_LOCATION_SUMMARY = {
  name: true,
};

export const SHALLOW_MANAGER_SUMMARY = {
  id: true,
  firstName: true,
  lastName: true,
};

export const COMPENSATION_BONUS_FRAGMENT = {
  id: true,
  amountCents: true,
  bonusCategory: true,
  effectiveDate: true,
  description: true,
};

export const CREATE_PERFORMANCE_RATING_SUMMARY = {
  __typename: true,
};
export const CREATE_SCENARIO_ALERT = {
  __typename: true,
};

export const CREATE_APPROVAL_EMPLOYEE_SUMMARY = {
  __typename: true,
};

export const PERFORMANCE_RATING_SUMMARY = {
  rating: true,
  id: true,
  __typename: true,
};

export const SHALLOW_COMPENSATION_CHANGE_COHORT_FRAGMENT = {
  uuid: true,
  name: true,
  createdAt: true,
  updatedAt: true,
  salaryChangeBps: true,
  salaryChangeCount: true,
  equityChangeBps: true,
  equityChangeCount: true,
  bonusCents: true,
  members: EMPLOYEE_ID_FRAGMENT,
  policyNotes: true,
  kind: true,
  totalSalaryChangeCents: true,
  totalEquityChangeShares: true,
  __typename: true,
};

export const SHALLOW_COHORT_ADJUSTMENTS_FRAGMENT = {
  uuid: true,
  name: true,
  policyNotes: true,
  salaryChangeCount: true,
  salaryChangeBps: true,
  equityChangeCount: true,
  equityChangeBps: true,
};

export const SHALLOW_PROPOSED_ADJUSTMENT_FRAGMENT = {
  createdAt: true,
  adjustmentType: true,
  salaryChange: true,
  salaryChangeUnit: true,
  equityChange: true,
  equityChangeUnit: true,
  equityChangeValue: true,
  equityUnitType: true,
  bonusCents: true,
  cohort: SHALLOW_COHORT_ADJUSTMENTS_FRAGMENT,
};

export const COMPENSATION_SUGGESTION_FRAGMENT = {
  employeeId: true,
  modelVersion: true,
  adjustments: {
    description: true,
    proposedAmount: true,
  },
  totalProposedSalaryCents: true,
  errorMessage: true,
};

export const SHALLOW_AFFECTED_TEAM_MEMBER_FRAGMENT = {
  uuid: true,
  employee: EMPLOYEE_ID_FRAGMENT,
  currentYearlySalaryCents: true,
  currentTotalEquityShares: true,
  currentVariableCommissionCents: true,
  newVariableCommissionCents: true,
  proposedAdjustments: SHALLOW_PROPOSED_ADJUSTMENT_FRAGMENT,
  proposedTotalYearlySalaryCents: true,
  proposedTotalEquityShares: true,
};

export const SHARED_WITH_FRAGMENT = {
  profile: {
    email: true,
    fullName: true,
    id: true,
  },
  accessType: true,
  isAdmin: true,
};

export const SCENARIO_EMPLOYEE_FRAGMENT = {
  id: true,
  employee: {
    id: true,
  },
  customBudgetIneligibilityReason: true,
  promotionEligible: true,
  proposedLevel: {
    id: true,
    lowerBoundAnnualSalaryCents: true,
    upperBoundAnnualSalaryCents: true,
    salaryCurrencyCode: true,
    jobTrack: true,
    levelNum: true,
  },
  approvers: {
    id: true,
    profile: {
      id: true,
      fullName: true,
    },
    approvalType: true,
    scenarioEmployee: {
      id: true,
      employee: {
        firstName: true,
      },
    },
  },
  sharedWith: SHARED_WITH_FRAGMENT,
};

export const SHALLOW_ROLE_TYPE_FRAGMENT = {
  jobFamily: true,
  lowerBoundAnnualSalaryCents: true,
  upperBoundAnnualSalaryCents: true,
  levelNum: true,
};

export const SHALLOW_EMPLOYEE_FRAGMENT = {
  role: SHALLOW_ROLE_TYPE_FRAGMENT,
  firstName: true,
  lastName: true,
  displayFullName: true,
  id: true,
  performanceRatings: PERFORMANCE_RATING_SUMMARY,
};

export type ShallowCompensationChangeScenarioType = FieldsSelection<
  CompensationChangeScenarioType,
  typeof SHALLOW_COMPENSATION_CHANGE_SCENARIO_FRAGMENT
>;

export type ShallowCompensationPlanConfigType = FieldsSelection<CompensationChangeScenarioType, typeof COMP_PLAN_CONFIG_FRAGMENT>;

export type ShallowCompensationChangeScenarioListType = ShallowCompensationChangeScenarioType[];

export const getCompensationChangeScenarioList = async (client: Client): Promise<Array<ShallowCompensationChangeScenarioType>> => {
  const response = await client.query({
    __name: 'getCompensationChangeScenarioList',
    compensationScenarios: SHALLOW_COMPENSATION_CHANGE_SCENARIO_FRAGMENT,
  });
  return response.compensationScenarios;
};

export const compPlanConfigQuery = {
  fragment: COMP_PLAN_CONFIG_FRAGMENT,
};

export const getCompensationScenarioDetail = async (uuid: string, phoenixClient: Client): Promise<CompensationScenarioDetailType> => {
  const response = await phoenixClient.query({
    __name: 'getCompensationScenarioDetail',
    compensationScenarioDetail: [{ uuid }, COMPENSATION_SCENARIO_DETAIL_FRAGMENT],
  });
  return response.compensationScenarioDetail;
};

export const makeEmployeeApprovalDetails = (
  approvers: unifiedEmployeeType['approvers'],
  profileId: number | undefined,
): approvalMapValueType => {
  //dumb little optimization to avoid iterating over the approvers array twice
  //means we can't support approval chains longer than 10000 lol
  let highestRank = 10000;
  let profileRank = 10000;
  const approvalStatus = approvers.reduce(
    (acc, curr) => {
      if (curr.approverRank < highestRank && curr.approvalType === 'APPROVED') {
        highestRank = curr.approverRank;
      }
      if (curr.profileId === profileId) {
        profileRank = curr.approverRank;
        acc['approverId'] = curr.id;
        if (curr.approvalType === 'APPROVED') {
          acc.approvedByMe = true;
        } else {
          acc.approvedByMe = false;
          acc.isUnapprovedByMe = true;
        }
      }
      if (curr.approvalType === 'PENDING') {
        acc.isUnapproved = true;
        acc.pendingApprovers.push({ approverId: curr.id, approverName: curr.profileFullName || '' });
      }
      acc.approvedBySuperior = highestRank < profileRank;
      return acc; // Return a new object each time
    },
    {
      approvedByMe: false,
      approvedBySuperior: false,
      approverId: null,
      isUnapproved: false,
      isUnapprovedByMe: false,
      pendingApprovers: [] as PendingApproversType[],
    } as approvalMapValueType,
  );
  return approvalStatus;
};

export const makeAllEmployeesApprovalMap = (unifiedEmployees: unifiedEmployeeType[], profileId: number): AllEmployeesApprovalData =>
  unifiedEmployees.reduce(
    (acc, employee) => {
      const employeeId = employee.employeeId as string;
      acc.unifiedEmployeeMap[employeeId] = employee;
      const employeeApprovalDetails = makeEmployeeApprovalDetails(employee.approvers, profileId);

      acc.approvalMap[employeeId] = employeeApprovalDetails;
      if (employeeApprovalDetails.approverId) {
        acc.myApprovals.push(employeeApprovalDetails.approverId);
      }
      if (employeeApprovalDetails.isUnapproved) {
        acc.unApprovedEmployees.push({
          employeeId,
          isUnapproved: employeeApprovalDetails.isUnapproved,
          isUnapprovedByMe: employeeApprovalDetails.isUnapprovedByMe,
          displayFullName: employee.displayFullName || '',
          pendingApprovers: employeeApprovalDetails.pendingApprovers,
        });
      }
      if (employeeApprovalDetails.isUnapprovedByMe) {
        acc.unApprovedByMeEmployees.push({
          employeeId,
          isUnapproved: employeeApprovalDetails.isUnapproved,
          isUnapprovedByMe: employeeApprovalDetails.isUnapprovedByMe,
          displayFullName: employee.displayFullName || '',
        });
      }
      return acc;
    },
    {
      unifiedEmployeeMap: {} as { [key: string]: unifiedEmployeeType },
      approvalMap: {} as ApprovalMapType,
      unApprovedEmployees: [] as UnapprovedEmployeeType[],
      unApprovedByMeEmployees: [] as UnapprovedEmployeeType[],
      myApprovals: [] as number[],
    },
  );

export const getCompensationScenarioDetailV2 = async (
  uuid: string,
  profiledId: number,
  phoenixClient: Client,
): Promise<ExtendedCompScenarioDetailPhoenixRisingType> => {
  const response = await phoenixClient.query({
    __name: 'getCompensationScenarioDetailPhoenixRising',
    compensationScenarioDetailPhoenixRising: [{ uuid }, COMP_SCENARIO_DETAIL_PHOENIX_RISING_FRAGMENT],
  });
  const { unifiedEmployeeMap, approvalMap, unApprovedEmployees, unApprovedByMeEmployees, myApprovals } = makeAllEmployeesApprovalMap(
    response.compensationScenarioDetailPhoenixRising.unifiedEmployees,
    profiledId,
  );
  const data = {
    scenario: response.compensationScenarioDetailPhoenixRising,
    unifiedEmployeeMap: unifiedEmployeeMap,
    approvalMap: approvalMap,
    unApprovedEmployees: unApprovedEmployees,
    unApprovedByMeEmployees: unApprovedByMeEmployees,
    myApprovals: myApprovals,
  };
  return data;
};
export const getCompScenarioEmployeesV2Query = async (client: Client): Promise<EmployeeType[]> => {
  const response = await client.query({
    __name: 'getCompScenarioEmployeesDjango',
    employeesV2: [
      {
        filters: {
          mustBeActive: true,
        },
      },
      EMPLOYEE_FRAGMENT,
    ],
  });
  return response.employeesV2 as EmployeeType[];
};
export const getCompScenarioEmployeeQuery = async (phoenixClient: Client): Promise<Array<EmployeeType>> => {
  const response = await phoenixClient.query({
    __name: 'getCompScenarioEmployees',
    employees: [
      {
        filters: {
          mustBeActive: true,
        },
      },
      EMPLOYEE_FRAGMENT,
    ],
  });
  return response.employees as EmployeeType[];
};

export const getCompensationScenarioDataDump = async (uuid: string, client: Client): Promise<CompensationScenarioDataDumpType> => {
  return await client.chain.query
    .compensationScenarioDataDump({ scenarioUuid: uuid })
    .get({ id: true, compensationChangeScenarioId: true, data: true, __typename: true });
};

export const getCompensationScenarioDataDumpV2 = async (
  uuid: string,
  profiledId: number,
  client: Client,
): Promise<CompensationScenarioDataDumpV2Type> => {
  const response = await client.chain.query
    .compensationScenarioDataDumpV2({ scenarioUuid: uuid })
    .get({ id: true, compensationChangeScenarioId: true, data: true, __typename: true });

  const { unifiedEmployeeMap, approvalMap, unApprovedEmployees, unApprovedByMeEmployees, myApprovals } = makeAllEmployeesApprovalMap(
    response.data.compensationScenarioDetail.unifiedEmployees,
    profiledId,
  );
  const pivotData = {
    scenario: response.data.compensationScenarioDetail,
    unifiedEmployeeMap: unifiedEmployeeMap,
    approvalMap: approvalMap,
    unApprovedEmployees: unApprovedEmployees,
    unApprovedByMeEmployees: unApprovedByMeEmployees,
    myApprovals: myApprovals,
  };
  return { ...response, data: { organization: response.data.organization, compensationScenarioDetail: pivotData } };
};

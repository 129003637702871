import { differenceInDays, isPast } from 'date-fns';
import React, { useState } from 'react';

const ChurnWarning: React.FC<{ churnedDate?: string }> = ({ churnedDate: churnedDateString }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!churnedDateString) {
    return null;
  }
  const churnedDate = new Date(churnedDateString);
  const daysUntilChurn = differenceInDays(churnedDate, new Date());
  const hasChurned = isPast(churnedDate) || daysUntilChurn === 0;
  if (!isVisible) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: '#8b5cf6',
        color: '#ffffff',
        padding: '0.5rem',
        textAlign: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 20,
      }}
    >
      <span role='img' aria-label='Warning Emoji'>
        ⚠️
      </span>{' '}
      Your Complete subscription {hasChurned ? `has ended.` : `is ending in ${daysUntilChurn} day${daysUntilChurn === 1 ? '' : 's'}.`}{' '}
      Please ask your admin to{' '}
      <a style={{ textDecoration: 'underline' }} href='mailto:sales@complete.so'>
        contact us
      </a>{' '}
      to renew your subscription.
      <button
        onClick={handleClose}
        style={{
          marginLeft: '1rem',
          background: 'none',
          border: 'none',
          color: '#ffffff',
          fontSize: '1rem',
          cursor: 'pointer',
          padding: 0,
        }}
      >
        &times;
      </button>
    </div>
  );
};

export default ChurnWarning;

import { FieldsSelection } from '@genql/runtime';
import { EmployeePlanningActivityLogSummary } from 'services/generated';
import { EMPLOYEE_VARIABLE_COMMISSIONS, GEOLOCATION_FRAGMENT } from 'services/queries';
export const ROLE_FRAGMENT = {
  jobFamily: true,
  lowerBoundAnnualSalaryCents: true,
  upperBoundAnnualSalaryCents: true,
  salaryCurrencyCode: true,
  level: true,
  jobTrack: true,
  levelNum: true,
  id: true,
  title: true,
};

export const SCENARIO_BUDGET_FRAGMENT = {
  id: true,
  name: true,
  quantity: true,
  unitDescriptor: true,
  scenario: {
    uuid: true,
  },
  scenarioEmployee: {
    id: true,
  },
};
export const SCENARIO_BUDGET_V2_FRAGMENT = {
  id: true,
  name: true,
  quantity: true,
  currencyCode: true,
  unitDescriptor: true,
  equityUnitType: true,
};
export const SHARED_WITH_FRAGMENT = {
  profile: {
    email: true,
    fullName: true,
    id: true,
  },
  accessType: true,
  isAdmin: true,
};

export const APPROVER_FRAGMENT = {
  id: true,
  profile: {
    id: true,
    fullName: true,
    email: true,
  },
  approvalType: true,
  approverRank: true,
  updatedAt: true,
  scenarioEmployee: {
    id: true,
    employee: {
      firstName: true,
    },
  },
};

export const SCENARIO_APPROVER_FRAGMENT = {
  __typename: true,
};

export const SCENARIO_EMPLOYEE_FRAGMENT = {
  __typename: true,
  id: true,
  employee: {
    id: true,
    displayFullName: true,
    workEmail: true,
    startDate: true,
    currentPayRateCents: true,
    performanceRatings: {
      rating: true,
      effectiveDate: true,
    },
  },
  compensationChangeScenario: {
    uuid: true,
  },
  scenariobudgetSet: SCENARIO_BUDGET_FRAGMENT,
  proposedLevel: {
    ...ROLE_FRAGMENT,
    // TODO: type the downstream values so that we are correctly expecting these fields to be present
    family: {
      uuid: true,
      name: true,
    },
  },
  proposedTier: true,
  proposedLocation: GEOLOCATION_FRAGMENT,
  promotionEligible: true,
  approvers: APPROVER_FRAGMENT,
  alerts: {
    id: true,
    status: true,
    type: true,
  },
  sharedWith: SHARED_WITH_FRAGMENT,
  customBudgetIneligibilityReason: true,
  comments: {
    id: true,
    content: true,
    createdAt: true,
    author: {
      id: true,
      fullName: true,
      email: true,
    },
  },
  additionalData: {
    value: true,
    columnName: true,
  },
  proposedTitle: true,
};

export const MANAGER_FRAGMENT = {
  id: true,
  firstName: true,
  lastName: true,
  displayFullName: true,
  workEmail: true,
};

export const EMPLOYMENT_FRAGMENT = {
  id: true,
  payRateInCents: true,
  payCurrency: true,
  effectiveDate: true,
  annualizedPayRate: true,
};

export const VESTING_SCHEDULE_FRAGMENT = {
  uuid: true,
  vestingDuration: true,
  vestingFrequency: true,
  name: true,
  description: true,
  createdAt: true,
  updatedAt: true,
  hasCliff: true,
  // TODO: remove this from the request. it's not necessary in most
  // cases as we're fetching the global vesting schedule list in
  // places where we need to display details of each vesting event.
  // e.g. CompPlanningIndividualsGraph
  events: true,
  __typename: true,
};

export const SECURITY_FRAGMENT = {
  uuid: true,
  issuedQuantity: true,
  issueDate: true,
  securityType: true,
  vestingStartDate: true,
  vestingSchedule: VESTING_SCHEDULE_FRAGMENT,
};

export const STAKEHOLDER_FRAGMENT = {
  securitySet: SECURITY_FRAGMENT,
};

export const EMPLOYEE_FRAGMENT = {
  id: true,
  firstName: true,
  lastName: true,
  displayFullName: true,
  currentJobTitle: true,
  workEmail: true,
  workLocation: {
    name: true,
  },
  manager: MANAGER_FRAGMENT,
  performanceRatings: {
    id: true,
    rating: true,
    effectiveDate: true,
  },
  currentPayRateCents: true,
  startDate: true,
  // profile: SHALLOW_PROFILE_SUMMARY,
  role: ROLE_FRAGMENT,
  tier: true,
  stakeholder: STAKEHOLDER_FRAGMENT,
  currentEmployment: EMPLOYMENT_FRAGMENT,
  employmentSet: EMPLOYMENT_FRAGMENT,
  // compensationbonusSet: COMPENSATION_BONUS_FRAGMENT,
  employeeCommissions: EMPLOYEE_VARIABLE_COMMISSIONS,
  location: GEOLOCATION_FRAGMENT,
};

export const COHORT_VARIABLE_COMMISSION_FRAGMENT = {
  payPeriod: true,
  payRateInCents: true,
  currencyCode: true,
  effectiveDate: true,
  endDate: true,
  paymentType: true,
};

export const COHORT_FRAGMENT = {
  uuid: true,
  scenario: {
    uuid: true,
  },
  name: true,
  createdAt: true,
  updatedAt: true,
  salaryChangeBps: true,
  salaryChangeCount: true,
  vestingSchedule: VESTING_SCHEDULE_FRAGMENT,
  equityChangeBps: true,
  equityChangeCount: true,
  equityUnitType: true,
  bonusCents: true,
  policyNotes: true,
  kind: true,
  totalSalaryChangeCents: true,
  totalEquityChangeShares: true,
  members: { id: true, firstName: true, lastName: true, currentPayRateCents: true },
  vestingStartDate: true,
  variableCommissionJson: COHORT_VARIABLE_COMMISSION_FRAGMENT,
};

export const PROPOSED_ADJUSTMENT_FRAGMENT = {
  createdAt: true,
  adjustmentType: true,
  salaryChange: true,
  salaryChangeUnit: true,
  equityChange: true,
  equityChangeUnit: true,
  vestingSchedule: VESTING_SCHEDULE_FRAGMENT,
  equityChangeValue: true,
  equityUnitType: true,
  bonusCents: true,
  cohort: COHORT_FRAGMENT,
  vestingStartDate: true,
  variableCommissionChange: true,
  variableCommissionJson: COHORT_VARIABLE_COMMISSION_FRAGMENT,
  securityVestingEvents: {
    vestDate: true,
    issuedQuantity: true,
    __typename: true,
  },
};

export const ROLE_CHANGE_COMPA_RATIO_FRAGMENT = {
  jobFamilyName: true,
  levelName: true,
  bandType: true,
  bandName: true,
  min: true,
  max: true,
  target: true,
  midpoint: true,
  compaRatio: true,
  currencyCode: true,
  payGeoLocation: GEOLOCATION_FRAGMENT,
  adjustedCompaRatio: true,
  targetVariableComp: true,
  targetPercentage: true,
};

export const EMPLOYEE_COMPA_RATIOS_FRAGMENT = {
  employeeId: true,
  compaRatios: {
    jobFamilyName: true,
    levelName: true,
    bandType: true,
    min: true,
    max: true,
    target: true,
    midpoint: true,
    compaRatio: true,
    currencyCode: true,
    payGeoLocation: GEOLOCATION_FRAGMENT,
    payRateInCents: true,
    adjustmentRateInCents: true,
    adjustedCompaRatio: true,
    targetVariableComp: true,
    targetPercentage: true,
    roleChangeCompaRatioData: ROLE_CHANGE_COMPA_RATIO_FRAGMENT,
  },
};

export const TEAM_MEMBER_FRAGMENT = {
  uuid: true,
  currentYearlySalaryCents: true,
  currentTotalEquityShares: true,
  currentVariableCommissionCents: true,
  newVariableCommissionCents: true,
  employee: {
    ...EMPLOYEE_FRAGMENT,
    location: GEOLOCATION_FRAGMENT,
  },
  proposedAdjustments: PROPOSED_ADJUSTMENT_FRAGMENT,
  proposedTotalYearlySalaryCents: true,
  proposedTotalEquityShares: true,
  customColumns: {
    verboseTenureDisplay: true,
    successionPlan: true,
  },
  employeeCompaRatios: EMPLOYEE_COMPA_RATIOS_FRAGMENT,
};

export const NOTIFY_APPROVAL_EMPLOYEE_FRAGMENT = {
  success: true,
};

export const COMPENSATION_SCENARIO_DETAIL_FRAGMENT = {
  uuid: true,
  name: true,
  salaryCurrentSpendCents: true,
  equityCurrentSpendShares: true,
  equityBudgetShares: true,
  hasEquityBudget: true,
  hasVariableCommission: true,
  salaryBudgetCents: true,
  financialYear: true,
  deadline: true,
  allTeamMembers: TEAM_MEMBER_FRAGMENT,
  scenarioEmployees: SCENARIO_EMPLOYEE_FRAGMENT,
  cohorts: COHORT_FRAGMENT,
  employees: EMPLOYEE_FRAGMENT,
  globalBudgets: SCENARIO_BUDGET_FRAGMENT,
  effectiveDate: true,
  implementedAt: true,
  archivedAt: true,
  vestingSchedule: VESTING_SCHEDULE_FRAGMENT,
  exchangeRates: {
    id: true,
  },
  sharePriceCents: true,
  totalVariableCompBudgetCents: true,
  totalVariableCompSpendCents: true,
};
export const PROPOSED_ADJUSTMENT_V2_FRAGMENT = {
  cohortId: true,
  createdAt: true,
  compType: true,
  changeAmount: true,
  vestingScheduleId: true,
  // variable comp specific types
  variableCompPayPeriod: true,
  variableCompPaymentType: true,
  variableCompEndDate: true,
  equityUnitType: true,
  vestingStartDate: true,
  vestingDescription: true,
  vestingDuration: true,
  vestingFrequency: true,
  hasCliff: true,
  vestingEvents: true,
  securityVestingEvents: {
    vestDate: true,
    issuedQuantity: true,
    __typename: true,
  },
};
export const APPROVER_FRAGMENT_V2 = {
  id: true,
  approvalType: true,
  profileId: true,
  approverRank: true,
  submittedById: true,
  profileFullName: true,
  profileEmail: true,
  submittedByFullName: true,
  submittedByEmail: true,
  updatedAt: true,
};
export const UNIFIED_EMPLOYEE_FRAGMENT = {
  employeeId: true,
  firstName: true,
  lastName: true,
  displayFullName: true,
  workEmail: true,
  employeeWorkLocation: true,
  managerId: true,
  managerDisplayFullName: true,
  managerFirstName: true,
  managerLastName: true,
  managerWorkEmail: true,
  roleId: true,
  roleJobFamily: true,
  roleJobFamilyId: true,
  roleSalaryCurrencyCode: true,
  roleLevel: true,
  roleJobTrack: true,
  roleJobTrackLevelDisplay: true,
  roleLevelNum: true,
  roleTitle: true,
  roleJobTrackLevelTitleDisplay: true,
  roleJobTrackLevelTitleFamilyDisplay: true,
  tier: true,
  currentEmploymentId: true,
  currentEmploymentPayRateCents: true,
  currentEmploymentPayCurrency: true,
  currentEmploymentEffectiveDate: true,
  currentEmploymentAnnualizedPayRate: true,
  performanceRatingLabel: true,
  performanceRatingEffectiveDate: true,
  performanceRatingValue: true,
  //location
  employeeLocationId: true,
  employeeLocationCityId: true,
  employeeLocationCityName: true,
  employeeLocationCountryId: true,
  employeeLocationCountryName: true,
  employeeLocationContinentId: true,
  employeeLocationContinentName: true,
  //proposed
  proposedRoleId: true,
  proposedFamilyUuid: true,
  proposedFamilyName: true,
  proposedTier: true,
  proposedRoleJobTrack: true,
  proposedRoleLevelNum: true,
  proposedRoleTitle: true,
  proposedRoleJobTrackLevelDisplay: true,
  proposedRoleJobTrackLevelTitleDisplay: true,
  scenarioEmployeeId: true,
  proposedLocationId: true,
  proposedLocationCityName: true,
  proposedLocationCountryName: true,
  proposedLocationContinentName: true,
  proposedLocationCityId: true,
  proposedLocationCountryId: true,
  proposedLocationContinentId: true,
  proposedTitle: true,
  sharedWithUser: true,

  employmentSet: {
    id: true,
    payRateInCents: true,
    payCurrency: true,
    effectiveDate: true,
    annualizedPayRate: true,
    jobTitle: true,
  },
  securities: {
    issuedQuantity: true,
    securityType: true,
    vestingScheduleUuid: true,
    vestingStartDate: true,
    vestingEvents: {
      vestDate: true,
      issuedQuantity: true,
      __typename: true,
    },
  },
  scenariobudgetSet: {
    id: true,
    name: true,
    quantity: true,
    unitDescriptor: true,
    equityUnitType: true,
    currencyCode: true,
  },
  promotionEligible: true,
  customBudgetIneligibilityReason: true,
  approvers: APPROVER_FRAGMENT_V2,
  suggestedAdjustmentCents: true,
  suggestedAdjustmentDescription: true,
  suggestedAdjustmentType: true,
  proposedAdjustments: PROPOSED_ADJUSTMENT_V2_FRAGMENT,
  alerts: {
    id: true,
    status: true,
    type: true,
  },
  // currentPayRateCents: true,
  startDate: true,

  // variable comp
  currentVariableCompInCents: true,
  newVariableCompInCents: true,
  employeeCommissions: EMPLOYEE_VARIABLE_COMMISSIONS,
  // Compa ratios
  employeeCompaRatios: EMPLOYEE_COMPA_RATIOS_FRAGMENT,
  rangePenetration: true,
  hasComments: true,
};
export const ADMIN_BUDGET_TABLE_FRAGMENT = {
  plannerName: true,
  plannerId: true,
  salaryBudget: true,
  salarySpend: true,
  equityBudget: true,
  equitySpend: true,
  variableCompBudget: true,
  variableCompSpend: true,
  bonusBudget: true,
  bonusSpend: true,
  averageSalaryIncrease: true,
  promotionsGiven: true,
  employeeCount: true,
};
export const COMP_SCENARIO_DETAIL_PHOENIX_RISING_FRAGMENT = {
  uuid: true,
  compScenarioComputeTime: true,
  name: true,
  salaryCurrentSpendCents: true,
  equityCurrentSpendShares: true,
  equityBudgetShares: true,
  hasEquityBudget: true,
  hasVariableCommission: true,
  salaryBudgetCents: true,
  financialYear: true,
  deadline: true,
  effectiveDate: true,
  implementedAt: true,
  archivedAt: true,
  launchedAt: true,
  vestingSchedule: VESTING_SCHEDULE_FRAGMENT,
  exchangeRates: {
    id: true,
  },
  globalBudgets: SCENARIO_BUDGET_V2_FRAGMENT,
  totalVariableCompBudgetCents: true,
  totalVariableCompSpendCents: true,
  totalCustomBonusBudgetCents: true,
  totalGlobalBonusBudgetCents: true,
  totalBonusBudgetSpendCents: true,
  unifiedEmployees: UNIFIED_EMPLOYEE_FRAGMENT,
  budgetRollups: ADMIN_BUDGET_TABLE_FRAGMENT,
  hasMeritMatrix: true,
  meritMatrixId: true,
  promotionTargetPercent: true,
  performanceRatingSchemeId: true,
  meritMatrixPerformanceRatingSchemeId: true,
  sharePriceCents: true,
};

export const SCENARIO_INVITE_FRAGMENT = {
  compensationChangeScenario: {
    uuid: true,
    sharedWith: SHARED_WITH_FRAGMENT,
  },
  __typename: true,
};

export const SCENARIO_EMPLOYEE_INVITE_FRAGMENT = {
  scenarioEmployee: SCENARIO_EMPLOYEE_FRAGMENT,
  __typename: true,
};

export const SCENARIO_APPROVER_DETAILS_FRAGMENT = {
  id: true,
  approvalType: true,
  firstViewComplete: true,
};

export const COMP_PLAN_CONFIG_FRAGMENT = {
  uuid: true,
  compPlanConfig: {
    reasons: true,
    approversSetting: true,
    customBudgetSetting: true,
    allEligible: true,
  },
  launchedAt: true,
  adminMessage: true,
  state: true,
  cycleClosedAt: true,
  approver: SCENARIO_APPROVER_DETAILS_FRAGMENT,
  adjustmentsImportedAt: true,
  shareWithManagers: true,
  implementedAt: true,
  deadline: true,
  effectiveDate: true,
};

const TOTAL_REWARDS_EQUITY_FRAGMENT = {
  securitySet: {
    certificateIdentifier: true,
    shareClass: true,
    awardType: true,
    issueDate: true,
    issuedQuantity: true,
    sharePriceCents: true,
    hasEarlyExercise: true,
    isExercisable: true,
    isVestable: true,
    exercisePriceCents: true,
    vestingStartDate: true,
    acceptanceStatus: true,
    status: true,
    securityType: true,
    createdAt: true,
    vestingSchedule: {
      vestingFrequency: true,
      vestingDuration: true,
      name: true,
      hasCliff: true,
      description: true,
      events: true,
      __typename: true,
    },
    __typename: true,
  },
  __typename: true,
};

export const NEW_TOTAL_REWARDS_STATEMENT_FRAGMENT = {
  id: true,
  employee: {
    id: true,
    displayFullName: true,
  },
  createdAt: true,
  isVisible: true,
  employeeLastViewedAt: true,
  averageSessionLengthSeconds: true,
  totalEmployeeViewCount: true,
  adminMessage: true,
  orgConfig: {
    orgIdentifier: true,
    compensationPhilosophy: true,
    showJobLadder: true,
    sharePriceCents: true,
  },
  totalRewardsData: {
    baseSalaryDifference: true,
    benefitPackageValueCents: true,
    benefitPackageCurrency: true,
    currentBaseSalaryCents: true,
    currentBaseSalaryCurrency: true,
    currentBaseSalaryIsNew: true,
    currentJobFamilyUuid: true,
    currentJobTitle: true,
    currentRoleId: true,
    currentRoleTitle: true,
    previousRoleId: true,
    previousRoleTitle: true,
    equityValueInCurrentCalendarYearCents: true,
    previousBaseSalaryCents: true,
    previousBaseSalaryCurrency: true,
    totalCompensationCents: true,
    totalEquityValueCents: true,
    totalUnvestedEquityValueCents: true,
    totalVestedEquityValueCents: true,
    totalVestedEquityUnits: true,
    totalUnvestedEquityUnits: true,
    equityCardData: {
      certificateIdentifier: true,
      exercisePriceUsdCents: true,
      issueDate: true,
      issuedSharesQuantity: true,
      monthsSinceVestingStarted: true,
      numSharesVested: true,
      percentageVestedInt: true,
      sharePriceUsdCents: true,
      totalValueUsdCents: true,
      vestingStartDate: true,
      vestingDuration: true,
      vestingFrequency: true,
      hasCliff: true,
      cliffDuration: true,
      cliffDurationUnit: true,
      events: true,
      vestingEvents: {
        vestDate: true,
        issuedQuantity: true,
        __typename: true,
      },
      __typename: true,
    },
    salaryCardData: {
      effectiveDate: true,
      annualizedBaseSalaryCents: true,
      jobTitle: true,
      currentEmployment: true,
      futureEmployment: true,
      initialEmployment: true,
      payRateInCents: true,
      payPeriod: true,
      payFrequency: true,
      payCurrency: true,
      differenceFromPreviousEmployment: true,
      __typename: true,
    },
    benefitPackage: {
      name: true,
      benefits: true,
    },
    teamMembers: {
      id: true,
      displayFullName: true,
      currentJobTitle: true,
      isManager: true,
      isYou: true,
      __typename: true,
    },
    __typename: true,
  },
  __typename: true,
};

export const TOTAL_REWARDS_STATEMENT_FRAGMENT = {
  id: true,
  employee: {
    displayFullName: true,
  },
  createdAt: true,
  isVisible: true,
  employeeLastViewedAt: true,
  averageSessionLengthSeconds: true,
  totalEmployeeViewCount: true,
  totalRewardsData: {
    adminMessage: true,
    currentCompensationCents: true,
    currentAnnualBonusCents: true,
    currentJobLevel: true,
    currentJobFamily: true,
    currentRoleId: true,
    currentRoleTitle: true,
    currentBenefitPackageId: true,
    previousRoleId: true,
    previousAnnualBonusCents: true,
    currentJobTitle: true,
    previousEquity: true,
    previousCompensationCents: true,
    previousJobLevel: true,
    previousJobTitle: true,
    stakeholder: TOTAL_REWARDS_EQUITY_FRAGMENT,
    employments: {
      annualizedPayRate: true,
      effectiveDate: true,
      employmentType: true,
      jobTitle: true,
      payCurrency: true,
      payFrequency: true,
      payPeriod: true,
      payRateInCents: true,
      __typename: true,
    },
    __typename: true,
  },
  __typename: true,
};

export const ACTIVITY_LOG_QUERY = {
  items: {
    action: true,
    actor: {
      displayName: true,
      profile: {
        fullName: true,
        id: true,
      },
    },
    onBehalfOf: {
      displayName: true,
      profile: {
        fullName: true,
        id: true,
      },
    },

    value: true,
    prevValue: true,
    valueStr: true,
    prevValueStr: true,
    timestamp: true,
    payCurrency: true,
    comment: {
      content: true,
      id: true,
    },
  },
};
export type ActivitySummaryType = FieldsSelection<EmployeePlanningActivityLogSummary, typeof ACTIVITY_LOG_QUERY>;
export type ActivityLogItemType = ActivitySummaryType['items'];

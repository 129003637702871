import { detect } from 'detect-browser';
import React, { useEffect, useState } from 'react';

interface BrowserWarningProps {
  message?: string;
}

const BrowserWarning: React.FC<BrowserWarningProps> = ({ message = 'Please use a different browser for a better experience' }) => {
  const [isSafari, setIsSafari] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const browser = detect();
    const { name = '' } = browser || {};
    setIsSafari(name === 'safari');
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isSafari || !isVisible) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: '#8b5cf6',
        color: '#ffffff',
        padding: '0.5rem',
        textAlign: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 20,
      }}
    >
      <span role='img' aria-label='Warning Emoji'>
        ⚠️
      </span>{' '}
      {message}
      <button
        onClick={handleClose}
        style={{
          marginLeft: '1rem',
          background: 'none',
          border: 'none',
          color: '#ffffff',
          fontSize: '1rem',
          cursor: 'pointer',
          padding: 0,
        }}
      >
        &times;
      </button>
    </div>
  );
};

export default BrowserWarning;

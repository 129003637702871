const {
  linkTypeMap,
  createClient: createClientOriginal,
  generateGraphqlOperation,
  assertSameVersion,
} = require('@genql/runtime')
var typeMap = linkTypeMap(require('./types.cjs'))

var version = '2.10.12'
assertSameVersion(version)

module.exports.version = version

module.exports.createClient = function(options) {
  options = options || {}
  var optionsCopy = {
    url: undefined,
    queryRoot: typeMap.Query,
    mutationRoot: typeMap.Mutation,
    subscriptionRoot: typeMap.Subscription,
  }
  for (var name in options) {
    optionsCopy[name] = options[name]
  }
  return createClientOriginal(optionsCopy)
}

module.exports.enumApproversSetting = {
  MANUAL: 'MANUAL',
  UPLOAD_HIERARCHY: 'UPLOAD_HIERARCHY',
}

module.exports.enumAuditLogAction = {
  UNKNOWN: 'UNKNOWN',
  UPDATE_NOTE: 'UPDATE_NOTE',
  COMMENTED: 'COMMENTED',
  CREATE_COMPENSATION_SCENARIO: 'CREATE_COMPENSATION_SCENARIO',
  UPDATE_SCENARIO_LAUNCHED: 'UPDATE_SCENARIO_LAUNCHED',
  UPDATE_NAME: 'UPDATE_NAME',
  UPDATE_EQUITY: 'UPDATE_EQUITY',
  UPDATE_SALARY: 'UPDATE_SALARY',
  UPDATE_BONUS: 'UPDATE_BONUS',
  UPDATE_VARIABLE_COMP: 'UPDATE_VARIABLE_COMP',
  UPDATE_TITLE: 'UPDATE_TITLE',
  PROMOTED: 'PROMOTED',
  REMOVE_PROMOTION: 'REMOVE_PROMOTION',
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
}

module.exports.enumBenchmarkType = {
  salary: 'salary',
  equity: 'equity',
}

module.exports.enumBonusCategory = {
  ANNUAL: 'ANNUAL',
  QUARTERLY: 'QUARTERLY',
  MONTHLY: 'MONTHLY',
  RELOCATION: 'RELOCATION',
  COMMISSION: 'COMMISSION',
  OTHER: 'OTHER',
}

module.exports.enumBudgetUnitDescriptor = {
  SALARY_PERCENTAGE: 'SALARY_PERCENTAGE',
  SALARY_ABSOLUTE: 'SALARY_ABSOLUTE',
  BONUS_PERCENTAGE: 'BONUS_PERCENTAGE',
  BONUS_ABSOLUTE: 'BONUS_ABSOLUTE',
  VARIABLE_COMMISSION_PERCENTAGE: 'VARIABLE_COMMISSION_PERCENTAGE',
  VARIABLE_COMMISSION_ABSOLUTE: 'VARIABLE_COMMISSION_ABSOLUTE',
  EQUITY_ABSOLUTE: 'EQUITY_ABSOLUTE',
}

module.exports.enumCandidateMessageKind = {
  GENERIC: 'GENERIC',
  PERSONALIZED: 'PERSONALIZED',
}

module.exports.enumCompaRatioDisplayMode = {
  PERCENT_DELTA: 'PERCENT_DELTA',
  DECIMAL: 'DECIMAL',
  PERCENTILE: 'PERCENTILE',
}

module.exports.enumCompensationChangeScenarioAccessType = {
  VIEWER: 'VIEWER',
  EDITOR: 'EDITOR',
  NO_ACCESS: 'NO_ACCESS',
}

module.exports.enumCompensationChangeScenarioAdjustmentType = {
  INDIVIDUAL: 'INDIVIDUAL',
  COHORT: 'COHORT',
  COMPANY_WIDE: 'COMPANY_WIDE',
}

module.exports.enumCompensationChangeUnitType = {
  BPS: 'BPS',
  COUNT: 'COUNT',
}

module.exports.enumCompensationPlanningCustomColumns = {
  verbose_tenure_display: 'verbose_tenure_display',
  succession_plan: 'succession_plan',
}

module.exports.enumCompensationScenarioCohortEquityUnitType = {
  ISO: 'ISO',
  NSO: 'NSO',
  RSU: 'RSU',
}

module.exports.enumCompensationScenarioCohortKind = {
  GROUP: 'GROUP',
  INDIVIDUAL: 'INDIVIDUAL',
}

module.exports.enumContentType = {
  jobfamily: 'jobfamily',
  role: 'role',
  hiringrange: 'hiringrange',
  employee: 'employee',
}

module.exports.enumCustomBudgetSetting = {
  NO_CUSTOM: 'NO_CUSTOM',
  CUSTOM_SALARY_AND_BONUS: 'CUSTOM_SALARY_AND_BONUS',
  CUSTOM_SALARY_AND_VARIABLE_COMP: 'CUSTOM_SALARY_AND_VARIABLE_COMP',
  CUSTOM_SALARY_BONUS_AND_VARIABLE_COMP:
    'CUSTOM_SALARY_BONUS_AND_VARIABLE_COMP',
  CUSTOM_SALARY: 'CUSTOM_SALARY',
  CUSTOM_EQUITY: 'CUSTOM_EQUITY',
  CUSTOM_BONUS: 'CUSTOM_BONUS',
  CUSTOM_VARIABLE_COMP: 'CUSTOM_VARIABLE_COMP',
  CUSTOM_SALARY_EQUITY: 'CUSTOM_SALARY_EQUITY',
  CUSTOM_SALARY_BONUS: 'CUSTOM_SALARY_BONUS',
  CUSTOM_SALARY_VARIABLE_COMP: 'CUSTOM_SALARY_VARIABLE_COMP',
  CUSTOM_EQUITY_SALARY: 'CUSTOM_EQUITY_SALARY',
  CUSTOM_EQUITY_BONUS: 'CUSTOM_EQUITY_BONUS',
  CUSTOM_EQUITY_VARIABLE_COMP: 'CUSTOM_EQUITY_VARIABLE_COMP',
  CUSTOM_BONUS_SALARY: 'CUSTOM_BONUS_SALARY',
  CUSTOM_BONUS_EQUITY: 'CUSTOM_BONUS_EQUITY',
  CUSTOM_BONUS_VARIABLE_COMP: 'CUSTOM_BONUS_VARIABLE_COMP',
  CUSTOM_VARIABLE_COMP_SALARY: 'CUSTOM_VARIABLE_COMP_SALARY',
  CUSTOM_VARIABLE_COMP_EQUITY: 'CUSTOM_VARIABLE_COMP_EQUITY',
  CUSTOM_VARIABLE_COMP_BONUS: 'CUSTOM_VARIABLE_COMP_BONUS',
  CUSTOM_SALARY_EQUITY_BONUS: 'CUSTOM_SALARY_EQUITY_BONUS',
  CUSTOM_SALARY_EQUITY_VARIABLE_COMP: 'CUSTOM_SALARY_EQUITY_VARIABLE_COMP',
  CUSTOM_SALARY_BONUS_EQUITY: 'CUSTOM_SALARY_BONUS_EQUITY',
  CUSTOM_SALARY_BONUS_VARIABLE_COMP: 'CUSTOM_SALARY_BONUS_VARIABLE_COMP',
  CUSTOM_SALARY_VARIABLE_COMP_EQUITY: 'CUSTOM_SALARY_VARIABLE_COMP_EQUITY',
  CUSTOM_SALARY_VARIABLE_COMP_BONUS: 'CUSTOM_SALARY_VARIABLE_COMP_BONUS',
  CUSTOM_EQUITY_SALARY_BONUS: 'CUSTOM_EQUITY_SALARY_BONUS',
  CUSTOM_EQUITY_SALARY_VARIABLE_COMP: 'CUSTOM_EQUITY_SALARY_VARIABLE_COMP',
  CUSTOM_EQUITY_BONUS_SALARY: 'CUSTOM_EQUITY_BONUS_SALARY',
  CUSTOM_EQUITY_BONUS_VARIABLE_COMP: 'CUSTOM_EQUITY_BONUS_VARIABLE_COMP',
  CUSTOM_EQUITY_VARIABLE_COMP_SALARY: 'CUSTOM_EQUITY_VARIABLE_COMP_SALARY',
  CUSTOM_EQUITY_VARIABLE_COMP_BONUS: 'CUSTOM_EQUITY_VARIABLE_COMP_BONUS',
  CUSTOM_BONUS_SALARY_EQUITY: 'CUSTOM_BONUS_SALARY_EQUITY',
  CUSTOM_BONUS_SALARY_VARIABLE_COMP: 'CUSTOM_BONUS_SALARY_VARIABLE_COMP',
  CUSTOM_BONUS_EQUITY_SALARY: 'CUSTOM_BONUS_EQUITY_SALARY',
  CUSTOM_BONUS_EQUITY_VARIABLE_COMP: 'CUSTOM_BONUS_EQUITY_VARIABLE_COMP',
  CUSTOM_BONUS_VARIABLE_COMP_SALARY: 'CUSTOM_BONUS_VARIABLE_COMP_SALARY',
  CUSTOM_BONUS_VARIABLE_COMP_EQUITY: 'CUSTOM_BONUS_VARIABLE_COMP_EQUITY',
  CUSTOM_VARIABLE_COMP_SALARY_EQUITY: 'CUSTOM_VARIABLE_COMP_SALARY_EQUITY',
  CUSTOM_VARIABLE_COMP_SALARY_BONUS: 'CUSTOM_VARIABLE_COMP_SALARY_BONUS',
  CUSTOM_VARIABLE_COMP_EQUITY_SALARY: 'CUSTOM_VARIABLE_COMP_EQUITY_SALARY',
  CUSTOM_VARIABLE_COMP_EQUITY_BONUS: 'CUSTOM_VARIABLE_COMP_EQUITY_BONUS',
  CUSTOM_VARIABLE_COMP_BONUS_SALARY: 'CUSTOM_VARIABLE_COMP_BONUS_SALARY',
  CUSTOM_VARIABLE_COMP_BONUS_EQUITY: 'CUSTOM_VARIABLE_COMP_BONUS_EQUITY',
  CUSTOM_SALARY_EQUITY_BONUS_VARIABLE_COMP:
    'CUSTOM_SALARY_EQUITY_BONUS_VARIABLE_COMP',
  CUSTOM_SALARY_EQUITY_VARIABLE_COMP_BONUS:
    'CUSTOM_SALARY_EQUITY_VARIABLE_COMP_BONUS',
  CUSTOM_SALARY_BONUS_EQUITY_VARIABLE_COMP:
    'CUSTOM_SALARY_BONUS_EQUITY_VARIABLE_COMP',
  CUSTOM_SALARY_BONUS_VARIABLE_COMP_EQUITY:
    'CUSTOM_SALARY_BONUS_VARIABLE_COMP_EQUITY',
  CUSTOM_SALARY_VARIABLE_COMP_EQUITY_BONUS:
    'CUSTOM_SALARY_VARIABLE_COMP_EQUITY_BONUS',
  CUSTOM_SALARY_VARIABLE_COMP_BONUS_EQUITY:
    'CUSTOM_SALARY_VARIABLE_COMP_BONUS_EQUITY',
  CUSTOM_EQUITY_SALARY_BONUS_VARIABLE_COMP:
    'CUSTOM_EQUITY_SALARY_BONUS_VARIABLE_COMP',
  CUSTOM_EQUITY_SALARY_VARIABLE_COMP_BONUS:
    'CUSTOM_EQUITY_SALARY_VARIABLE_COMP_BONUS',
  CUSTOM_EQUITY_BONUS_SALARY_VARIABLE_COMP:
    'CUSTOM_EQUITY_BONUS_SALARY_VARIABLE_COMP',
  CUSTOM_EQUITY_BONUS_VARIABLE_COMP_SALARY:
    'CUSTOM_EQUITY_BONUS_VARIABLE_COMP_SALARY',
  CUSTOM_EQUITY_VARIABLE_COMP_SALARY_BONUS:
    'CUSTOM_EQUITY_VARIABLE_COMP_SALARY_BONUS',
  CUSTOM_EQUITY_VARIABLE_COMP_BONUS_SALARY:
    'CUSTOM_EQUITY_VARIABLE_COMP_BONUS_SALARY',
  CUSTOM_BONUS_SALARY_EQUITY_VARIABLE_COMP:
    'CUSTOM_BONUS_SALARY_EQUITY_VARIABLE_COMP',
  CUSTOM_BONUS_SALARY_VARIABLE_COMP_EQUITY:
    'CUSTOM_BONUS_SALARY_VARIABLE_COMP_EQUITY',
  CUSTOM_BONUS_EQUITY_SALARY_VARIABLE_COMP:
    'CUSTOM_BONUS_EQUITY_SALARY_VARIABLE_COMP',
  CUSTOM_BONUS_EQUITY_VARIABLE_COMP_SALARY:
    'CUSTOM_BONUS_EQUITY_VARIABLE_COMP_SALARY',
  CUSTOM_BONUS_VARIABLE_COMP_SALARY_EQUITY:
    'CUSTOM_BONUS_VARIABLE_COMP_SALARY_EQUITY',
  CUSTOM_BONUS_VARIABLE_COMP_EQUITY_SALARY:
    'CUSTOM_BONUS_VARIABLE_COMP_EQUITY_SALARY',
  CUSTOM_VARIABLE_COMP_SALARY_EQUITY_BONUS:
    'CUSTOM_VARIABLE_COMP_SALARY_EQUITY_BONUS',
  CUSTOM_VARIABLE_COMP_SALARY_BONUS_EQUITY:
    'CUSTOM_VARIABLE_COMP_SALARY_BONUS_EQUITY',
  CUSTOM_VARIABLE_COMP_EQUITY_SALARY_BONUS:
    'CUSTOM_VARIABLE_COMP_EQUITY_SALARY_BONUS',
  CUSTOM_VARIABLE_COMP_EQUITY_BONUS_SALARY:
    'CUSTOM_VARIABLE_COMP_EQUITY_BONUS_SALARY',
  CUSTOM_VARIABLE_COMP_BONUS_SALARY_EQUITY:
    'CUSTOM_VARIABLE_COMP_BONUS_SALARY_EQUITY',
  CUSTOM_VARIABLE_COMP_BONUS_EQUITY_SALARY:
    'CUSTOM_VARIABLE_COMP_BONUS_EQUITY_SALARY',
}

module.exports.enumCustomerRequestKind = {
  OfferReportExport: 'OfferReportExport',
  CompensationPlanExport: 'CompensationPlanExport',
  HelpMe: 'HelpMe',
  TeamPageExport: 'TeamPageExport',
  CustomBudgetImport: 'CustomBudgetImport',
  BenchmarkReportImport: 'BenchmarkReportImport',
  DisabledLogin: 'DisabledLogin',
}

module.exports.enumDataTableColumnType = {
  Boolean: 'Boolean',
  Date: 'Date',
  Number: 'Number',
  String: 'String',
}

module.exports.enumDataTableColumnVisibility = {
  Unavailable: 'Unavailable',
  DefaultUnchecked: 'DefaultUnchecked',
  DefaultChecked: 'DefaultChecked',
}

module.exports.enumFlatfileImportKind = {
  BENCHMARK_REPORT_IMPORT: 'BENCHMARK_REPORT_IMPORT',
  HRIS: 'HRIS',
  CAPTABLE: 'CAPTABLE',
  COMPENSATION_PLAN_CONFIGURATION: 'COMPENSATION_PLAN_CONFIGURATION',
  PERFORMANCE_RATING: 'PERFORMANCE_RATING',
  LEVELS_IMPORT: 'LEVELS_IMPORT',
  EMPLOYEE_COMMISSIONS: 'EMPLOYEE_COMMISSIONS',
  TRANCHE_REPORT: 'TRANCHE_REPORT',
  LEVELS_UPLOAD: 'LEVELS_UPLOAD',
}

module.exports.enumInviteType = {
  VIEWER: 'VIEWER',
  EDITOR: 'EDITOR',
  NO_ACCESS: 'NO_ACCESS',
}

module.exports.enumOfferAccessType = {
  VIEWER: 'VIEWER',
  EDITOR: 'EDITOR',
  NO_ACCESS: 'NO_ACCESS',
  OWNER: 'OWNER',
}

module.exports.enumOfferStateType = {
  accepted: 'accepted',
  rejected: 'rejected',
  open: 'open',
  draft: 'draft',
  archived: 'archived',
}

module.exports.enumOfferTemplateState = {
  DRAFT: 'DRAFT',
  ARCHIVED: 'ARCHIVED',
}

module.exports.enumOperationMessageKind = {
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  PERMISSION: 'PERMISSION',
  VALIDATION: 'VALIDATION',
}

module.exports.enumOrdering = {
  ASC: 'ASC',
  DESC: 'DESC',
}

module.exports.enumPerformanceRatingStatus = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
}

module.exports.enumProfileGroupType = {
  admin: 'admin',
  talent_admin: 'talent_admin',
  recruiter: 'recruiter',
  lead: 'lead',
  employee: 'employee',
  hrbp: 'hrbp',
}

module.exports.enumScenarioApprovalValue = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
}

module.exports.enumScenarioEmployeeAccessType = {
  VIEWER: 'VIEWER',
  EDITOR: 'EDITOR',
  NO_ACCESS: 'NO_ACCESS',
}

module.exports.enumScenarioEmployeeApprovalValue = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
}

module.exports.enumScenarioEmployeePromotionEligible = {
  ELIGIBLE: 'ELIGIBLE',
  NOT_ELIGIBLE: 'NOT_ELIGIBLE',
  UNDER_CONSIDERATION: 'UNDER_CONSIDERATION',
}

module.exports.enumScenarioEmployeeStatus = {
  DONE: 'DONE',
  IN_PROGRESS: 'IN_PROGRESS',
  NOT_STARTED: 'NOT_STARTED',
  IN_REVIEW: 'IN_REVIEW',
}

module.exports.enumSuggestedAdjustmentType = {
  MERIT_MATRIX: 'MERIT_MATRIX',
  PROMOTION_TARGET: 'PROMOTION_TARGET',
}

module.exports.generateQueryOp = function(fields) {
  return generateGraphqlOperation('query', typeMap.Query, fields)
}
module.exports.generateMutationOp = function(fields) {
  return generateGraphqlOperation('mutation', typeMap.Mutation, fields)
}
module.exports.generateSubscriptionOp = function(fields) {
  return generateGraphqlOperation('subscription', typeMap.Subscription, fields)
}
module.exports.everything = {
  __scalar: true,
}

var schemaExports = require('./guards.cjs')
for (var k in schemaExports) {
  module.exports[k] = schemaExports[k]
}

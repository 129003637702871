import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';

interface ModalContext {
  setModal: Dispatch<SetStateAction<ReactNode>>;
}

const ModalDispatchContext = createContext<ModalContext | undefined>(undefined);

export const ModalProvider: FC = ({ children }) => {
  const [modal, setModal] = useState<ReactNode | null>(null);

  return (
    <ModalDispatchContext.Provider value={{ setModal }}>
      {modal}
      {children}
    </ModalDispatchContext.Provider>
  );
};

export const useModal = () => {
  const modalContext = useContext(ModalDispatchContext);

  const setModal = modalContext?.setModal;

  const openModal = (modal: ReactNode) => {
    setModal && setModal(modal);
    const nextDiv = document.querySelector('#__next');
    if (nextDiv) {
      nextDiv.removeAttribute('inert');
    }
  };

  const closeModal = () => {
    setModal && setModal(null);
  };

  return {
    openModal,
    closeModal,
  };
};
